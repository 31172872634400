import { Button, Grid, Menu, Layout, Paper, Table, ButtonGroup, Typography, Alert, Loader, Modal, Input, MultipleAutocomplete, MultipleAutocompleteOption } from "../../components";
import useSWR from 'swr'
import API from "../../API";
import moment from "moment";
import { COMPANY_OPTIONS } from "../../constants";
import currency from 'currency.js'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useAPI, useAxios, useToggle } from '../../hooks'
import { Chip, Divider } from "@mui/material";
import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import AuthContext from "../../context/auth";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';
import { formatCPF, getBidStatusColor, getBidStatusLabel } from "../../utils";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";
import { useFormik } from "formik";
import { DenyBidValidationSchema } from "@next-pontos/validations";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { GridFilterInputValueProps, GridFilterItem, GridFilterOperator } from "@mui/x-data-grid"
import { APP_NAME } from "@next-pontos/settings";
import { enqueueSnackbar } from "notistack";

const statusOptions = [
    {
        value: 'PENDING',
        label: 'Pendente'
    },
    { value: 'ACCEPTED', label: 'Aceita' },
    {value: 'DENIED', label: 'Recusada'},
    {value: 'AWAITING_ORDER', label: 'Aguardando Emissão'},
    {value: 'ON_ORDER', label: 'Em Emissão'},
    {value: 'DONE', label: 'Finalizada'}
]

function StatusSelector(props: GridFilterInputValueProps) {
    const { item, applyValue } = props;
  
    return (
        <MultipleAutocomplete
        variant="standard"
            label="Status"
            options={statusOptions}
            value={statusOptions.filter(option => item?.value?.includes(option.value))}
            onChange={(value) => {
                applyValue({
                    ...item,
                    value: (value as unknown as MultipleAutocompleteOption[]).map(item => item.value)
                })
            }}
        />
    );
  }
  
const statusSelectorOperator: GridFilterOperator[] = [
{
    label: 'é igual a',
    value: 'in',
    getValueAsString: (values) => {
        return values.map((item: string) => statusOptions.find(({value}) => value === item)?.label).join(', ')
    },
    getApplyFilterFn: (filterItem: GridFilterItem) => {
        if (!Array.isArray(filterItem.value) || filterItem.value.length === 0 || !filterItem.value) {
            return null;
        }

        return ({ value }) => {
            return filterItem.value.includes(value)
        };
    },
    InputComponent: StatusSelector,
}];  

export function Bids () { 
    const theme = useTheme()
    const [bids, refetchBids] = useAxios('/bid')
    const [api, postAPI] = useAxios({
        method: 'POST'
    }, {
        manual: true
    })

    const [bid, fetchBid] = useAxios('/bid', {
        manual: true
    })

    const [deletedBid, deleteBid] = useAxios('/bid', {
        manual: true
    })

    const [password, fetchPassword] = useAxios('/account', {
        manual: true
    })

    const { me } = useContext(AuthContext)
    const [deleteModalVisibility, deleteModalVisibilityControls] = useToggle(false)
    const [denyModalVisibility, denyModalVisibilityControls] = useToggle(false)
    const [accountModalVisibility, accountModalVisibilityControls] = useToggle(false)
    const [denyBidId, setDenyBidId] = useState<undefined | string | number>()
    const [deleteBidId, setDeleteBidId] = useState<undefined | string | number>()
    const [passwordData, setPasswordData] = useState<null | { password: string, secondary_password: string}>(null)

    const navigate = useNavigate()

    const denyFormik = useFormik({
        initialValues: {
            reason: ''
        },
        validationSchema: DenyBidValidationSchema,
        validateOnChange: true,
        validateOnMount: true,
        onSubmit: async (values) => {
            await postAPI({ url: `/bid/${denyBidId}/deny`, 
                data: values
            })

            denyModalVisibilityControls.setFalse()

            refetchBids()
        }
    })

    const handleRevealPassword = useCallback(async () => {
        const passwordResponse = await fetchPassword({
            url: `/account/${bid?.data?.account_id}/password`,
            method: 'GET'
        })

        setPasswordData(passwordResponse.data)
    }, [fetchPassword, bid?.data?.account_id])

    useEffect(() => {
        if (!denyModalVisibility) {
            setDenyBidId(undefined)
        }
    }, [denyModalVisibility])

    useEffect(() => {
        if (!accountModalVisibility) {
            setPasswordData(null)
        }
    }, [accountModalVisibility])

    return (
        <Fragment>
            <Modal
                open={denyModalVisibility}
                onClose={denyModalVisibilityControls.setFalse}
                title="Qual o motivo da recusa?"
                onConfirm={denyFormik.handleSubmit}
                confirmLabel="Confirmar"
            >
                <div style={{
                    width: 400
                }}>
                    <Input 
                        label="Motivo"
                        name="reason"
                        onChange={denyFormik.handleChange}
                        onBlur={denyFormik.handleBlur}
                        value={denyFormik.values.reason}
                        helperText={denyFormik.touched.reason && denyFormik.errors.reason}
                        error={!!denyFormik.touched.reason && !!denyFormik.errors.reason}
                    />
                </div>
            </Modal>
            <Modal
                open={deleteModalVisibility}
                onClose={() => {
                    deleteModalVisibilityControls.setFalse()
                    setDeleteBidId('')
                }}
                title="Você tem certeza que deseja excluir esta oferta?"
                onConfirm={async () => {
                    const { status } = await deleteBid({
                        url: `/bid/${deleteBidId}`,
                        method: 'DELETE'
                    })

                    if (status <= 299) {
                        enqueueSnackbar('Oferta excluída com sucesso!', {variant: 'success'})
                    }

                    deleteModalVisibilityControls.setFalse()
                    refetchBids()
                }}
                confirmLabel="Confirmar"
            >
                <div style={{
                    width: 400
                }}>
                    <Typography>
                        Esta ação é irreversível e será deletada permanentemente.
                    </Typography>
                </div>
            </Modal>
            <Modal
                open={accountModalVisibility}
                onClose={accountModalVisibilityControls.setFalse}
                title="Dados da Conta"
            >
                {password.loading || bid.loading ? (
                    <div style={{textAlign: 'center', width: 600}}>
                        <Loader />
                    </div>
                ) : (<Grid container spacing={2} width={600}>
                    <Grid item xs={12}>
                        <Grid container direction="row">
                            <Grid item xs={6}>
                                <Typography fontWeight="bold">
                                    Clube Ativo:
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography textAlign="end">
                                    {bid?.data?.account?.is_membership_active ? 'Sim' : 'Não'}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container direction="row">
                            <Grid item xs={6}>
                                <Typography fontWeight="bold">
                                    Login:
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography textAlign="end">
                                    {bid?.data?.account?.login}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container direction="row">
                            <Grid item xs={6}>
                                <Typography fontWeight="bold">
                                    Senha:
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                    <Typography textAlign="end">
                                {
                                    passwordData ? (
                                        passwordData?.password
                                    ) : (
                                    <div style={{cursor: 'pointer'}} onClick={handleRevealPassword}>
                                        <VisibilityIcon fontSize="inherit" />
                                    </div>
                                    )
                                }
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container direction="row">
                            <Grid item xs={6}>
                                <Typography fontWeight="bold">
                                    Contrassenha:
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                    <Typography textAlign="end">
                                {
                                    passwordData ? (
                                        passwordData?.secondary_password ?? '-'
                                    ) : (
                                    <div style={{cursor: 'pointer'}} onClick={handleRevealPassword}>
                                        <VisibilityIcon fontSize="inherit" />
                                    </div>
                                    )
                                }
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container direction="row">
                            <Grid item xs={6}>
                                <Typography fontWeight="bold">
                                    Nome do Titular:
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography textAlign="end">
                                    {bid?.data?.account?.holder_name}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container direction="row">
                            <Grid item xs={6}>
                                <Typography fontWeight="bold">
                                    E-mail do Titular:
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography textAlign="end">
                                    {bid?.data?.account?.holder_email}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container direction="row">
                            <Grid item xs={6}>
                                <Typography fontWeight="bold">
                                    CPF do Titular:
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography textAlign="end">
                                    {formatCPF(bid?.data?.account?.holder_cpf || '')}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>)}
            </Modal>
            <Layout>
                <Grid container justifyContent="center" padding={3} gap={2}>
                    {api.loading || bids.loading ? (
                        <div style={{
                            display: 'flex',
                            flex: '1',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <Loader />
                        </div>
                    ) : (
                    <Fragment>
                        <Grid item xs={12} sm={11}>
                            <Alert color="info" icon={false}>
                                Esta listagem inicialmente apenas mostra ofertas com status <span style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>Em Emissão</span> e <span style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>Aguardando Emissão</span>.<br/>Para ver todos os registros, limpe os filtros da tabela.
                            </Alert>
                        </Grid>
                        <Grid item xs={12} sm={11}>
                        {
                            bids.data.find((item: { status: string }) => item.status === 'ON_ORDER') ? (
                                    <Alert color="warning" icon={false} sx={{marginBottom: 2}}>
                                        <strong>Você possui ofertas em emissão!</strong><br/>
                                        Não se esqueça de finalizar a oferta quando não houverem mais emissões a serem feitas.
                                    </Alert>
                            ) : null
                        }
                            <Paper elevation={1}>
                            <Table 
                                initialState={{
                                    filter: {
                                        filterModel: {
                                            items: [
                                                {
                                                    field: 'status',
                                                    operator: 'in',
                                                    value: ['AWAITING_ORDER', 'ON_ORDER']
                                                }
                                            ]
                                        }
                                    }
                                }}
                                getRowClassName={(params) => { 
                                    const olderThanWeek = moment().diff(params.row.created_at, 'days') >= 7
                                    
                                    return olderThanWeek && params.row.status === 'ACCEPTED' ? `row-color-danger` : ''
                                }}
                                columns={[
                                    { field: 'id', headerName: 'ID', width: 50 },
                                    { field: 'created_at', headerName: 'Data', flex: 1, valueFormatter: ({value}) => {
                                        return moment(value).format('DD/MM/YYYY HH:mm:ss')
                                    } },
                                    { field: 'user', headerName: 'Fornecedor', flex: 1 },
                                    { field: 'company', headerName: 'Programa', flex: 1 },
                                    { field: 'amount', headerName: 'Quantidade', flex: 1, renderCell: ({row, value}) => {
                                        return (
                                            <Fragment>
                                                <Typography variant="body2">
                                                    {Number(value).toLocaleString('pt-BR')}
                                                </Typography>
                                                {!!row.used_miles && (<Typography variant="body2" fontWeight="bold" marginLeft="5px" color={theme.palette.error.main}>
                                                    (-{Number(row.used_miles).toLocaleString('pt-BR')})
                                                </Typography>)}
                                            </Fragment>
                                        )
                                    }, valueGetter: ({ row }) => {
                                        return currency(row.amount).subtract(row.used_miles).value
                                    }},
                                    { field: 'price_per_k', headerName: 'Valor do Milheiro', width: 130, valueFormatter: ({value}) => {
                                        return currency(value).format({
                                            symbol: 'R$ ',
                                            decimal: ',',
                                            separator: '.'
                                        })
                                    }},
                                    { field: 'pax', headerName: 'CPFs Disponíveis', flex: 1, renderCell: ({row, value}) => {
                                        return (
                                            <Fragment>
                                                <Typography variant="body2">
                                                    {value}
                                                </Typography>
                                                {!!row.used_pax && !!row.pax && (<Typography variant="body2" fontWeight="bold" marginLeft="5px" color={theme.palette.error.main}>
                                                    (-{row.used_pax})
                                                </Typography>)}
                                            </Fragment>
                                        )
                                    },
                                    valueGetter: ({value, row}) => {
                                        return value ? currency(value).subtract(row.used_pax).value : '∞'
                                    }},
                                    {
                                        field: 'status', headerName: 'Status', flex: 1, 
                                        valueFormatter: ({ value }) => {
                                            return getBidStatusLabel(value)
                                        },
                                        renderCell: ({ formattedValue, row }) => {
                                            return (
                                                <Chip color={getBidStatusColor(row.status)} label={formattedValue} />
                                            )
                                        },
                                        filterOperators: statusSelectorOperator
                                    },
                                    {
                                        field: 'actions', headerName: '', hideable: false, width: 200, align: 'right', sortable: false, filterable: false, disableColumnMenu: true, renderCell: ({row}) => {
                                            const messageText = encodeURI(`Olá, ${row.user}! Tudo bem? Me chamo ${me?.name} e estou entrando em contato para falar da sua oferta número ${row.id} na ${APP_NAME}.`)

                                            const menuOptions = [
                                                { label: 'Ver Detalhes', onClick: async () => {
                                                    navigate(`/ofertas/${row.id}`)
                                                    }
                                                }
                                            ]

                                            // if (row.status === 'PENDING' || row.status === 'DENIED') {
                                            menuOptions.push({ label: row.status === 'PENDING' || row.status === 'DENIED' ? 'Aceitar' : 'Reenviar Aceite', onClick: async () => {
                                                await postAPI({ url: `/bid/${row.id}/accept` })
                                                refetchBids()
                                                }
                                            })
                                            // }

                                            if (row.status === 'PENDING' || row.status === 'ACCEPTED' || row.status === 'AWAITING_ORDER'  || row.status === 'ON_ORDER') {
                                                menuOptions.push({ label: 'Recusar', onClick: async () => {
                                                        setDenyBidId(row.id)
                                                        denyModalVisibilityControls.setTrue()
                                                    }
                                                })
                                            }

                                            if (row.status === 'ACCEPTED' || row.status === 'DENIED') {
                                                menuOptions.push({ 
                                                    label: 'Excluir', 
                                                    onClick: async () => {
                                                        setDeleteBidId(row.id)
                                                        deleteModalVisibilityControls.setTrue()
                                                    }
                                                })
                                            }

                                            if (row.status === 'AWAITING_ORDER' || row.status === 'ON_ORDER') {
                                                menuOptions.push({ 
                                                    label: 'Ver Conta', 
                                                    onClick: async () => {
                                                        fetchBid({
                                                            url: `/bid/${row.id}`
                                                        })

                                                        accountModalVisibilityControls.setTrue()
                                                    }
                                                })

                                                menuOptions.push({ 
                                                    label: 'Emitir', 
                                                    onClick: async () => {
                                                        navigate(`/ofertas/${row.id}/emissao`)
                                                    }
                                                })
                                            }

                                            if (row.status === 'ON_ORDER') {
                                                menuOptions.push({ 
                                                    label: 'Finalizar', 
                                                    onClick: async () => {
                                                        await postAPI({ url: `/bid/${row.id}/finish` })
                                                        refetchBids()
                                                        }
                                                })
                                            }
                                            
                                            return (
                                                <ButtonGroup>
                                                    <Button 
                                                        variant="outlined"
                                                        onClick={() => 
                                                            window.open(`https://api.whatsapp.com/send?phone=${row.whatsapp}&text=${messageText}`, '_blank')
                                                        }
                                                    >
                                                        <WhatsAppIcon />
                                                    </Button>
                                                    <Button 
                                                        variant="outlined"
                                                        onClick={() => 
                                                            window.open(`https://t.me/${row.telegram.replace('@', '')}?text=${messageText}`, '_blank')
                                                        }
                                                        disabled={!row.telegram}
                                                    >
                                                        <TelegramIcon />
                                                    </Button>
                                                    {me?.role === 'ADMIN' && (<Menu
                                                        options={menuOptions}
                                                    >
                                                        <MoreHorizIcon />
                                                    </Menu>)}
                                                </ButtonGroup>
                                            )
                                        }
                                    },
                                ]}
                                rows={bids?.data?.map((item: any) => ({
                                    id: item.id,
                                    user: item.user.name,
                                    created_at: item.created_at,
                                    company: COMPANY_OPTIONS.find(cia => cia.value === item.company)?.label,
                                    amount: item.amount,
                                    used_miles: item?.orders
                                        ?.filter((item: {confirmation: string}) => ['PENDING', 'CONFIRMED'].includes(item.confirmation))
                                        ?.reduce((prev: number, curr: {miles: number}) => currency(prev).add(curr.miles).value, 0),
                                    used_pax: item?.orders
                                        ?.filter((item: {confirmation: string}) => ['PENDING', 'CONFIRMED'].includes(item.confirmation))
                                        ?.reduce((prev: number, curr: {pax: number}) => currency(prev).add(curr.pax).value, 0),
                                    price_per_k: item.price_per_k,
                                    pax: item.pax ? Number(item.pax) : null,
                                    status: item.status,
                                    whatsapp: item.user.whatsapp,
                                    telegram: item.user.telegram
                                })) || []}
                            />
                            </Paper>
                        </Grid>
                    </Fragment>)}
                </Grid>
            </Layout>
        </Fragment>
    )
}