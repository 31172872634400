import { Menu, ButtonGroup, Grid, Layout, Paper, Table, Loader, Checkbox, Input, CurrencyInput, NumericInput } from "../../components";
import useSWR from 'swr'
import API from "../../API";
import moment from "moment";
import { COMPANY_OPTIONS } from "../../constants";
import { ChangeEvent, useContext } from "react";
import AuthContext from "../../context/auth";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {useNavigate} from 'react-router-dom'
import { useAxios } from "../../hooks";
import { debounce } from "lodash";
import { PriceSetting, PriceSettingsInput } from "./PriceSettingsInput";

export function Programs () {
    const navigate = useNavigate()

    const [programs, refetchProgram] = useAxios(`/program`)
    const [updatedProgram, updateProgram] = useAxios({
        method: 'PUT',
    }, {
        manual: true
    })
    
    const update = async (company: string, data: {avg_price_base?: number | string, min_amount?: number | string, active?: boolean, sell_price?: number | string, program_price_setting?: Array<PriceSetting & { direction: 'SELL' | 'BUY' }>}) => {
        await updateProgram({
            url: `/program/${company}`,
            method: 'PUT',
            data
        })

        refetchProgram()
    }

    const debouncedUpdate = debounce(update, 1000)

    return (
        <Layout>
            <Grid container justifyContent="center" minHeight={"calc(100vh - 64px)"} padding={3} >
                {programs.loading || updatedProgram.loading ? (
                    <div style={{
                        display: 'flex',
                        flex: '1',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Loader />
                    </div>
                ) :(<Grid item xs={12} sm={10}>
                    <Paper elevation={1}>
                    <Table 
                        getRowId={row => row.company}
                        rowHeight={65}
                        hideFooter
                        disableColumnFilter
                        disableColumnMenu
                        columns={[
                            { field: 'company', headerName: 'Programa', width: 300, sortable: false, valueGetter: ({value}) => {
                                return COMPANY_OPTIONS.find(item => item.value === value)?.label
                            } },
                            { field: 'sell_price', headerName: 'Valor de Venda (R$)',sortable: false, flex: 1, 
                            valueFormatter: ({value}) => Number(value), 
                            renderCell: ({ formattedValue, row }) => {
                                return (
                                    <CurrencyInput
                                        onChangeValue={(_, originalValue) => {
                                            debouncedUpdate(row.company, { sell_price: originalValue })
                                        }}
                                        value={formattedValue}
                                    />
                                )
                            } },
                            { field: 'avg_price_base', headerName: 'Valor de Compra (R$)',sortable: false, flex: 1, 
                            valueFormatter: ({value}) => Number(value), 
                            renderCell: ({ row }) => {
                                return (
                                    <PriceSettingsInput 
                                        value={row.program_price_settings}
                                        onChange={value => {
                                            update(row.company, {
                                                program_price_setting: value.map((item) => ({
                                                    ...item,
                                                    direction: 'BUY'
                                                }))
                                            })
                                        }}
                                    />
                                )
                            } },
                            { field: 'min_amount', headerName: 'Quantidade Mínima de Compra', flex: 1, sortable: false, 
                            valueFormatter: ({value}) => Number(value), 
                            renderCell: ({formattedValue, row}) => {
                                return (
                                    <NumericInput
                                        onChange={({target: { value }}) => {
                                            debouncedUpdate(row.company, { min_amount: value })
                                        }}
                                        value={formattedValue}
                                    />
                                )
                            } },
                            { field: 'active', headerName: 'Ativo', width: 100, sortable: false, renderCell: ({value, row}) => {
                                return <Checkbox checked={value} onChange={({target: { checked }}) => {
                                    update(row.company, {
                                        active: checked
                                    })
                                }} />
                            }},
                        ]}
                        rows={programs?.data || []}
                    />
                    </Paper>
                </Grid>)}
            </Grid>
        </Layout>
    )
}