import { Alert, Button, Grid, Input, Layout, Loader, PhoneInput, Typography } from "../../components";
import { Paper } from "../../components";
import { useFormik } from "formik";
import { UpdateProfileSchema, UpdateUserBankingDetailsSchema } from "@next-pontos/validations";
import { useAxios } from "../../hooks";
import {useEffect, useContext, Fragment} from 'react'
import useSWR from 'swr'
import { Tooltip } from "@mui/material";
import AuthContext from "../../context/auth";
import { formatCEP, formatCNPJ, formatCPF } from "../../utils";

export function Profile () { 
    const { me } = useContext(AuthContext)

    const [banking, updateBanking] = useAxios({
        url: `/user/${me?.id}/bank-details`,
        method: 'PUT'
    }, {
        manual: true
    })

    const [profile, updateProfile] = useAxios({
        url: `/user/${me?.id}`,
        method: 'PUT'
    }, {
        manual: true
    })

    
    const formik = useFormik({
        initialValues: {
            name: me?.name || '',
            email: me?.email || '',
            cpf: me?.cpf || '',
            phone_number: me?.phone_number || '',
            whatsapp: me?.whatsapp || '',
            telegram: me?.telegram || '',
            cep: me?.cep || '',
            address_street: me?.address_street || '',
            address_number: me?.address_number || '',
            neighborhood: me?.address_neighborhood || '',
            complement: me?.address_complement || '',
            city: me?.address_city || '',
            state: me?.address_state || '',
            password: '',
            password_confirmation: ''
        },
        onSubmit: async (values) => {
            await updateProfile({ data: values })
            
            window.location.reload()
        },
        validateOnMount: true,
        validationSchema: UpdateProfileSchema
    })

    const bankingFormik = useFormik({
        initialValues: {
            pix_type: me?.pix_type || '',
            pix_key: me?.pix_key || ''
        },
        onSubmit: async values => {
            await updateBanking({ data: values })

            window.location.reload()
        },
        validateOnMount: true,
        validateOnChange: true,
        validationSchema: UpdateUserBankingDetailsSchema
    })

    useEffect(() => {
        if (me) {
            formik.resetForm({
                values: {
                    name: me?.name || '',
                    email: me?.email || '',
                    cpf: formatCPF(me?.cpf || ''),
                    phone_number: me?.phone_number || '',
                    whatsapp: me?.whatsapp || '',
                    telegram: me?.telegram || '',
                    cep: formatCEP(me?.cep || ''),
                    address_street: me?.address_street || '',
                    address_number: me?.address_number || '',
                    neighborhood: me?.address_neighborhood || '',
                    complement: me?.address_complement || '',
                    city: me?.address_city || '',
                    state: me?.address_state || '',
                    password: '',
                    password_confirmation: ''
                },
            })

            formik.setTouched({}, false)

            const formattedPixKey = me?.pix_type === 'CPF' ? formatCPF(me?.pix_key || '') : me?.pix_key || ''

            bankingFormik.resetForm({
                values: {
                    pix_type: me?.pix_type || '',
                    pix_key: formattedPixKey
                },
            })
        }
    }, [me])

    const { setFieldValue } = formik

    const cleanCEP = formik.values.cep.replace(/\D/ig, '')

    const cepSearch = useSWR(`https://viacep.com.br/ws/${cleanCEP}/json/`, (...args) => { 
        if (cleanCEP.length === 8) {
            return fetch(...args).then(res => res.json())
        }

        return
    })

    useEffect(() => {
        if (cepSearch.data && !cepSearch.isLoading && !cepSearch.error) {
            setFieldValue('address_street', cepSearch.data.logradouro || '')
            setFieldValue('city', cepSearch.data.localidade || '')
            setFieldValue('state', cepSearch.data.uf || '')
            setFieldValue('neighborhood', cepSearch.data.bairro || '')
        }
    }, [cepSearch.data])

    return (
        <Layout>
            <Grid container justifyContent="center" gap={4} minHeight={"calc(100vh - 64px)"} alignItems="flex-start" paddingTop={5}>
                {banking.loading || profile.loading ? (
                    <div style={{
                        display: 'flex',
                        flex: '1',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Loader />
                    </div>
                ) : (<Fragment>
                    <Grid item xs={12} sm={4}>
                        <Paper elevation={1}>
                        <form onSubmit={formik.handleSubmit}>
                        <Grid container padding={2} spacing={2} justifyContent="center">
                            <Grid item xs={12} marginBottom={2}>
                                <Typography textAlign={"center"} variant="h5">Meu Perfil</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Input 
                                    label="Nome Completo"
                                    name="name"
                                    disabled
                                    onChange={formik.handleChange}
                                    value={formik.values.name}
                                    helperText={formik.touched.name && formik.errors.name}
                                    error={!!formik.touched.name && !!formik.errors.name}
                                    onBlur={formik.handleBlur}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}> 
                                <Input 
                                    label="Email"
                                    name="email"
                                    disabled
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email}
                                    helperText={formik.touched.email && formik.errors.email}
                                    error={!!formik.touched.email && !!formik.errors.email}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Input 
                                    label="CPF"
                                    name="cpf"
                                    disabled
                                    onBlur={formik.handleBlur}
                                    onChange={(event) => {
                                        formik.setFieldValue('cpf', event.target.value.replace(/\D/g, '')
                                        .replace(/(\d{3})(\d)/, '$1.$2')
                                        .replace(/(\d{3})(\d)/, '$1.$2')
                                        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
                                        .replace(/(-\d{2})\d+?$/, '$1'))
                                    }}
                                    value={formik.values.cpf}
                                    helperText={formik.touched.cpf && formik.errors.cpf}
                                    error={!!formik.touched.cpf && !!formik.errors.cpf}
                                /> 
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <PhoneInput 
                                    label="Telefone"
                                    inputProps={{
                                        name: 'phone_number'
                                    }}
                                    onChange={value => {
                                        formik.setFieldValue('phone_number', value, true)
                                    }}
                                    value={formik.values.phone_number}
                                    // helperText={formik.touched.phone_number && formik.errors.phone_number}
                                    onBlur={formik.handleBlur}
                                    error={!!formik.touched.phone_number && !!formik.errors.phone_number}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <PhoneInput 
                                    label="WhatsApp"
                                    inputProps={{
                                        name: 'whatsapp'
                                    }}
                                    onChange={value => {
                                        formik.setFieldValue('whatsapp', value, true)
                                    }}
                                    value={formik.values.whatsapp}
                                    // helperText={formik.touched.whatsapp && formik.errors.whatsapp}
                                    onBlur={formik.handleBlur}
                                    error={!!formik.touched.whatsapp && !!formik.errors.whatsapp}
                                />
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <Input 
                                    label="Telegram"
                                    name="telegram"
                                    onChange={(event) => {
                                        const value = event.target.value.replace('@', '')
                                        
                                        formik.setFieldValue('telegram', value.length > 0 ? `@${value}` : '')  
                                    }}
                                    value={formik.values.telegram}
                                    helperText={formik.touched.telegram && formik.errors.telegram}
                                    onBlur={formik.handleBlur}
                                    error={!!formik.touched.telegram && !!formik.errors.telegram}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Input 
                                    label="CEP"
                                    name="cep"
                                    onChange={(event) => {
                                        formik.setFieldValue('cep', event.target.value.replace(/\D/g,'').replace(/(\d{5})(\d)/,'$1-$2')
                                    )
                                    }}
                                    value={formik.values.cep}
                                    helperText={formik.touched.cep && formik.errors.cep}
                                    onBlur={formik.handleBlur}
                                    error={!!formik.touched.cep && !!formik.errors.cep}
                                />
                            </Grid>
                            <Grid item xs={12} sm={9}>
                                <Input 
                                    label="Logradouro"
                                    name="address_street"
                                    onChange={formik.handleChange}
                                    value={formik.values.address_street}
                                    helperText={formik.touched.address_street && formik.errors.address_street}
                                    onBlur={formik.handleBlur}
                                    error={!!formik.touched.address_street && !!formik.errors.address_street}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Input 
                                    label="Número"
                                    name="address_number"
                                    onChange={formik.handleChange}
                                    value={formik.values.address_number}
                                    helperText={formik.touched.address_number && formik.errors.address_number}
                                    onBlur={formik.handleBlur}
                                    error={!!formik.touched.address_number && !!formik.errors.address_number}
                                />
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <Input 
                                    label="Complemento"
                                    name="complement"
                                    onChange={formik.handleChange}
                                    value={formik.values.complement}
                                    helperText={formik.touched.complement && formik.errors.complement}
                                    onBlur={formik.handleBlur}
                                    error={!!formik.touched.complement && !!formik.errors.complement}
                                />
                            </Grid>
                            <Grid item xs={12} sm={7}>
                                <Input 
                                    label="Bairro"
                                    name="neighborhood"
                                    onChange={formik.handleChange}
                                    value={formik.values.neighborhood}
                                    helperText={formik.touched.neighborhood && formik.errors.neighborhood}
                                    onBlur={formik.handleBlur}
                                    error={!!formik.touched.neighborhood && !!formik.errors.neighborhood}
                                />
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <Input 
                                    label="Cidade"
                                    name="city"
                                    onChange={formik.handleChange}
                                    value={formik.values.city}
                                    helperText={formik.touched.city && formik.errors.city}
                                    onBlur={formik.handleBlur}
                                    error={!!formik.touched.city && !!formik.errors.city}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Input 
                                    label="Estado"
                                    name="state"
                                    onChange={formik.handleChange}
                                    value={formik.values.state}
                                    helperText={formik.touched.state && formik.errors.state}
                                    onBlur={formik.handleBlur}
                                    error={!!formik.touched.state && !!formik.errors.state}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Tooltip arrow title="Sua senha deve conter no mínimo 8 carateres, uma letra minúscula, uma letra maiúscula e um número.">
                                    <Input 
                                        label="Senha"
                                        type="password"
                                        name="password"
                                        onChange={formik.handleChange}
                                        helperText={formik.touched.password && formik.errors.password}
                                        onBlur={formik.handleBlur}
                                        error={!!formik.touched.password && !!formik.errors.password}
                                    />
                                </Tooltip>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Input 
                                    label="Confirmar Senha"
                                    type="password"
                                    name="password_confirmation"
                                    onChange={formik.handleChange}
                                    helperText={formik.touched.password_confirmation && formik.errors.password_confirmation}
                                    onBlur={formik.handleBlur}
                                    error={!!formik.touched.password_confirmation && !!formik.errors.password_confirmation}
                                />
                            </Grid>
                            <Grid item marginTop={2}>
                                <Button disabled={!formik.isValid} type="submit">Salvar</Button>
                            </Grid>
                        </Grid>
                        </form>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        {!me?.pix_key && (<Alert color="error" icon={false} style={{
                            marginBottom: 10
                        }}>
                            <strong>Preencha seus dados bancários!</strong>
                            <br />
                            Estas informaçōes são necessárias para enviarmos os seus pagamentos.
                        </Alert>)}
                        <Alert color="warning" icon={false} style={{
                            marginBottom: 30
                        }}>
                            Estamos enfrentando problemas ao efetuar pagamentos ao <strong>Banco Inter</strong>.<br />
                            Por favor, insira uma chave de outro banco.
                        </Alert>
                        <Paper elevation={1} >
                        <form onSubmit={bankingFormik.handleSubmit}>
                        <Grid container padding={2} spacing={2} justifyContent="center">
                            <Grid item xs={12} marginBottom={2}>
                                <Typography textAlign={"center"} variant="h5">Dados Bancários</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Input 
                                    label="Tipo de Chave"
                                    name="pix_type"
                                    select
                                    options={[
                                        { value: 'CPF', label: 'CPF'},
                                        { value: 'CNPJ', label: 'CNPJ'},
                                        { value: 'EMAIL', label: 'Email'},
                                        { value: 'PHONE_NUMBER', label: 'Telefone'},
                                        { value: 'RANDOM_KEY', label: 'Chave Aleatória'},
                                    ]}
                                    onChange={(event) => {
                                        bankingFormik.setFieldValue('pix_type', event.target.value)
                                        bankingFormik.setFieldValue('pix_key', '')
                                    }}
                                    value={bankingFormik.values.pix_type}
                                    helperText={bankingFormik.touched.pix_type && bankingFormik.errors.pix_type}
                                    error={!!bankingFormik.touched.pix_type && !!bankingFormik.errors.pix_type}
                                    onBlur={bankingFormik.handleBlur}
                                />
                            </Grid>
                            <Grid item xs={12}> 
                                {bankingFormik.values.pix_type === 'EMAIL' && (<Input 
                                    label="Email"
                                    name="pix_key"
                                    onChange={bankingFormik.handleChange}
                                    onBlur={bankingFormik.handleBlur}
                                    value={bankingFormik.values.pix_key}
                                    helperText={bankingFormik.touched.pix_key && bankingFormik.errors.pix_key}
                                    error={!!bankingFormik.touched.pix_key && !!bankingFormik.errors.pix_key}
                                />)}
                                {bankingFormik.values.pix_type === 'CPF' && (
                                    <Input 
                                        label="CPF"
                                        name="pix_key"
                                        onBlur={bankingFormik.handleBlur}
                                        onChange={(event) => {
                                            bankingFormik.setFieldValue('pix_key', formatCPF(event.target.value.replace(/\D/g, '')))
                                        }}
                                        value={bankingFormik.values.pix_key}
                                        helperText={bankingFormik.touched.pix_key && bankingFormik.errors.pix_key}
                                        error={!!bankingFormik.touched.pix_key && !!bankingFormik.errors.pix_key}
                                    /> 
                                )}
                                {bankingFormik.values.pix_type === 'CNPJ' && (
                                    <Input 
                                        label="CNPJ"
                                        name="pix_key"
                                        onBlur={bankingFormik.handleBlur}
                                        onChange={(event) => {
                                            bankingFormik.setFieldValue('pix_key', formatCNPJ(event.target.value.replace(/\D/g, '')))
                                        }}
                                        value={bankingFormik.values.pix_key}
                                        helperText={bankingFormik.touched.pix_key && bankingFormik.errors.pix_key}
                                        error={!!bankingFormik.touched.pix_key && !!bankingFormik.errors.pix_key}
                                    /> 
                                )}
                                {bankingFormik.values.pix_type === 'PHONE_NUMBER' && (
                                    <PhoneInput
                                        label="Telefone"
                                        inputProps={{
                                            name: 'pix_key'
                                        }}
                                        onChange={value => {
                                            bankingFormik.setFieldValue('pix_key', value, true)
                                        }}
                                        onlyCountries={['br']}
                                        value={bankingFormik.values.pix_key}
                                        onBlur={bankingFormik.handleBlur}
                                        error={!!bankingFormik.touched.pix_key && !!bankingFormik.errors.pix_key}
                                    />
                                )}
                                {bankingFormik.values.pix_type === 'RANDOM_KEY' && (
                                    <Input 
                                        label="Chave Aleatória"
                                        name="pix_key"
                                        onChange={bankingFormik.handleChange}
                                        value={bankingFormik.values.pix_key}
                                        helperText={bankingFormik.touched.pix_key && bankingFormik.errors.pix_key}
                                        onBlur={bankingFormik.handleBlur}
                                        error={!!bankingFormik.touched.pix_key && !!bankingFormik.errors.pix_key}
                                    />
                                )}
                            </Grid>
                            <Grid item marginTop={2}>
                                <Button disabled={!bankingFormik.isValid} type="submit">Salvar</Button>
                            </Grid>
                        </Grid>
                        </form>
                        </Paper>
                    </Grid>
                </Fragment>)}
            </Grid>
        </Layout>
    )
}