import { Button, Grid, Menu, Layout, Paper, Table, ButtonGroup, Loader, Input } from "../../components";
import moment from "moment";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useAxios } from '../../hooks'
import { Fragment, useContext } from "react";
import AuthContext from "../../context/auth";
import { Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { formatCPF } from "../../utils";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';
import { ROLE_OPTIONS } from "../../constants";
import { enqueueSnackbar } from "notistack";
import { CSVLink } from "react-csv";
import ImportExportIcon from '@mui/icons-material/FileDownload';
import { APP_NAME } from "@next-pontos/settings";

export function Users () {
    const navigate = useNavigate()
    const { me } = useContext(AuthContext)

    const [users, refetchUsers] = useAxios(`/user`)
    
    const [updatedRole, updateRole] = useAxios({
        url: '/user',
        method: 'PUT'
    }, {
        manual: true
    })

    const [status, updateUserStatus] = useAxios({
        method: 'PUT'
    }, {
        manual: true
    })
    
    return (
        <Layout>
            <Grid container justifyContent="center" padding={3} spacing={2}>
                {users.loading || status.loading ? (
                    <div style={{
                        display: 'flex',
                        flex: '1',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Loader />
                    </div>
                ) : ( 
                    <Fragment>
                    <Grid item xs={12} sm={10} textAlign="right">
                     <CSVLink
                        data={users?.data.map((user: {name: string, email: string}) => ([
                            user.name,
                            user.email
                        ]))}
                        filename="sendy-export.csv"
                        >
                            <Button>
                                <ImportExportIcon fontSize={"small"} style={{marginRight: 5}}/> Sendy
                            </Button>
                    </CSVLink>
                    </Grid>
                    <Grid item xs={12} sm={10}>
                        <Paper elevation={1}>
                        <Table 
                            rowHeight={65}
                            columns={[
                                { field: 'id', headerName: 'ID', width: 50 },
                                { field: 'created_at', headerName: 'Data', flex: 1, valueFormatter: ({value}) => {
                                    return moment(value).format('DD/MM/YYYY HH:mm:ss')
                                } },
                                { field: 'name', headerName: 'Nome', flex: 1.5 },
                                { field: 'email', headerName: 'E-mail', flex: 1.5 },
                                { field: 'role', headerName: 'Cargo',sortable: false, flex: 1.5, 
                                valueGetter: ({value}) => ROLE_OPTIONS.find(item => item.value === value)?.label, 
                                renderCell: ({ row }) => {
                                    return (
                                        <Input 
                                            select
                                            options={ROLE_OPTIONS}
                                            value={row.role}
                                            disabled={row.id === me?.id}
                                            onChange={async ({target}) => {
                                                const {status} = await updateRole({
                                                    url: `/user/${row.id}/role`,
                                                    data: {
                                                        role: target.value
                                                    }
                                                })

                                                if (status === 204) {
                                                    enqueueSnackbar('Cargo atualizado com sucesso!', {variant: 'success'})
                                                }
                                            }}
                                        />
                                    )
                                } },
                                { field: 'cpf', headerName: 'CPF', flex: 1, valueFormatter: ({value}) => formatCPF(value) },
                                { field: 'active', headerName: 'Status', flex: 1, valueFormatter: ({value}) => value ? 'Ativo' : 'Inativo', renderCell: ({value, formattedValue}) =>  (
                                    <Chip color={value ? 'success' : 'error'} label={formattedValue} />
                                ) },
                                { field: 'inviter', headerName: 'Indicado por', flex: 1, valueGetter: (value) => value?.value?.name, type: 'string' },
                                {
                                    field: 'actions', headerName: '', align: 'right', sortable: false, filterable: false, disableColumnMenu: true, width: 200, renderCell: ({row}) => {
                                        const messageText = encodeURI(`Olá, ${row.name}! Tudo bem? Sou ${me?.name}, da ${APP_NAME}.`)

                                        const menuOptions = [
                                            { label: 'Detalhes', onClick: () => { navigate(`/usuarios/${row.id}`) }}
                                        ]

                                        if (row.active) {
                                            menuOptions.push({
                                                label: 'Desativar', 
                                                onClick: async () => {
                                                    await updateUserStatus({
                                                        url: `/user/${row.id}/status`,
                                                        data: {
                                                            active: false
                                                        }
                                                    }) 

                                                    refetchUsers()
                                            }})
                                        } else {
                                            menuOptions.push({
                                                label: 'Ativar', 
                                                onClick: async () => { 
                                                    await updateUserStatus({
                                                        url: `/user/${row.id}/status`,
                                                        data: {
                                                            active: true
                                                        }
                                                    }) 

                                                    refetchUsers()
                                            }})
                                        }
                                        
                                        return (
                                            <ButtonGroup>
                                                <Button 
                                                    variant="outlined"
                                                    onClick={() => 
                                                        window.open(`https://api.whatsapp.com/send?phone=${row.whatsapp}&text=${messageText}`, '_blank')
                                                    }
                                                >
                                                    <WhatsAppIcon />
                                                </Button>
                                                <Button 
                                                    variant="outlined"
                                                    onClick={() => 
                                                        window.open(`https://t.me/${row.telegram.replace('@', '')}?text=${messageText}`, '_blank')
                                                    }
                                                    disabled={!row.telegram}
                                                >
                                                    <TelegramIcon />
                                                </Button>
                                                <Menu
                                                    options={menuOptions}
                                                >
                                                    <MoreHorizIcon />
                                                </Menu>
                                            </ButtonGroup>
                                        )
                                    }
                                }
                            ]}
                            rows={(users?.data || [])}
                        />
                        </Paper>
                    </Grid>
                
                </Fragment>)}
            </Grid>
        </Layout>
    )
}