import { Button, Grid, Input, Layout, Loader, PhoneInput, Table, Typography } from "../../../components";
import { Paper } from "../../../components";
import { useFormik } from "formik";
import { UpdateProfileSchema } from "@next-pontos/validations";
import { useAxios } from "../../../hooks";
import {Fragment, useEffect} from 'react'
import { formatCEP, formatCPF, formatDate, formatMoney, getBidStatusColor, getBidStatusLabel, getPaymentStatusColor, getPaymentStatusLabel } from "../../../utils";
import { useNavigate, useParams } from "react-router-dom";
import API from "../../../API";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Chip, Tooltip } from "@mui/material";
import currency from "currency.js";

export function User () { 
    const params = useParams()
    const navigate = useNavigate()

    const [user, refetchUser] = useAxios(`/user/${params.id}`)

    const [profile, updateProfile] = useAxios({
        url: `/user/${user?.data?.id}`,
        method: 'PUT'
    }, {
        manual: true
    })
    
    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            cpf: '',
            phone_number: '',
            whatsapp: '',
            telegram: '',
            cep: '',
            address_street: '',
            address_number: '',
            neighborhood: '',
            complement: '',
            city: '',
            state: '',
            password: '',
            password_confirmation: ''
        },
        onSubmit: async (values) => {
            await updateProfile({
                data: values
            })
            
            refetchUser()

            window.location.reload()
        },
        validateOnMount: true,
        validationSchema: UpdateProfileSchema
    })

    useEffect(() => {
        if (user.data) {
            formik.resetForm({
                values: {
                    name:  user.data?.name || '',
                    email: user.data?.email || '',
                    cpf: formatCPF(user.data?.cpf || ''),
                    phone_number: user.data?.phone_number || '',
                    whatsapp: user.data?.whatsapp || '',
                    telegram: user.data?.telegram || '',
                    cep: formatCEP(user.data?.cep || ''),
                    address_street: user.data?.address_street || '',
                    address_number: user.data?.address_number || '',
                    neighborhood: user.data?.address_neighborhood || '',
                    complement: user.data?.address_complement || '',
                    city: user.data?.address_city || '',
                    state: user.data?.address_state || '',
                    password: '',
                    password_confirmation: ''
                },
            })

            formik.setTouched({}, false)
        }
    }, [user.data])

    const { setFieldValue } = formik

    const cleanCEP = formik.values.cep.replace(/\D/ig, '')

    const [cepSearch, getCEPAddress] = useAxios(`https://viacep.com.br/ws/${cleanCEP}/json/`, {
        manual: true
    })

    useEffect(() => {
        if (cleanCEP.length === 8) {
            getCEPAddress()
        }
    }, [cleanCEP, getCEPAddress])
    
    useEffect(() => {
        if (cepSearch.data && !cepSearch.loading && !cepSearch.error) {
            setFieldValue('address_street', cepSearch.data.logradouro || '')
            setFieldValue('city', cepSearch.data.localidade || '')
            setFieldValue('state', cepSearch.data.uf || '')
            setFieldValue('neighborhood', cepSearch.data.bairro || '')
        }
    }, [cepSearch.data])
    
    return (
        <Layout>
            <Grid container justifyContent="center" gap={4} minHeight={"calc(100vh - 64px)"} alignItems="flex-start" paddingTop={5} >
                {profile.loading || user.loading ? (
                    <div style={{
                        display: 'flex',
                        flex: '1',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Loader />
                    </div>
                ) :(<Fragment>
                <Grid item xs={12} sm={4}>
                    <Paper elevation={1}>
                    <form onSubmit={formik.handleSubmit}>
                    <Grid container padding={2} spacing={2} justifyContent="center">
                        <Grid item xs={12} marginBottom={2}>
                            <Typography textAlign={"center"} variant="h5">Editar Usuário</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Input 
                                label="Nome Completo"
                                name="name"
                                disabled
                                onChange={formik.handleChange}
                                value={formik.values.name}
                                helperText={formik.touched.name && formik.errors.name}
                                error={!!formik.touched.name && !!formik.errors.name}
                                onBlur={formik.handleBlur}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}> 
                            <Input 
                                label="Email"
                                name="email"
                                disabled
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                                helperText={formik.touched.email && formik.errors.email}
                                error={!!formik.touched.email && !!formik.errors.email}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Input 
                                label="CPF"
                                name="cpf"
                                disabled
                                onBlur={formik.handleBlur}
                                onChange={(event) => {
                                    formik.setFieldValue('cpf', event.target.value.replace(/\D/g, '')
                                    .replace(/(\d{3})(\d)/, '$1.$2')
                                    .replace(/(\d{3})(\d)/, '$1.$2')
                                    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
                                    .replace(/(-\d{2})\d+?$/, '$1'))
                                }}
                                value={formik.values.cpf}
                                helperText={formik.touched.cpf && formik.errors.cpf}
                                error={!!formik.touched.cpf && !!formik.errors.cpf}
                            /> 
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <PhoneInput
                                label="Telefone"
                                inputProps={{
                                    name: 'phone_number'
                                }}
                                onChange={value => {
                                    formik.setFieldValue('phone_number', value, true)
                                }}
                                value={formik.values.phone_number}
                                // helperText={formik.touched.phone_number && formik.errors.phone_number}
                                onBlur={formik.handleBlur}
                                error={!!formik.touched.phone_number && !!formik.errors.phone_number}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <PhoneInput 
                                label="WhatsApp"
                                inputProps={{
                                    name: 'whatsapp'
                                }}
                                onChange={value => {
                                    formik.setFieldValue('whatsapp', value, true)
                                }}
                                value={formik.values.whatsapp}
                                // helperText={formik.touched.whatsapp && formik.errors.whatsapp}
                                onBlur={formik.handleBlur}
                                error={!!formik.touched.whatsapp && !!formik.errors.whatsapp}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Input 
                                label="Telegram"
                                name="telegram"
                                onChange={(event) => {
                                    const value = event.target.value.replace('@', '')
                                    
                                    formik.setFieldValue('telegram', value.length > 0 ? `@${value}` : '')  
                                }}
                                value={formik.values.telegram}
                                helperText={formik.touched.telegram && formik.errors.telegram}
                                onBlur={formik.handleBlur}
                                error={!!formik.touched.telegram && !!formik.errors.telegram}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Input 
                                label="CEP"
                                name="cep"
                                onChange={(event) => {
                                    formik.setFieldValue('cep', event.target.value.replace(/\D/g,'').replace(/(\d{5})(\d)/,'$1-$2')
                                  )
                                }}
                                value={formik.values.cep}
                                helperText={formik.touched.cep && formik.errors.cep}
                                onBlur={formik.handleBlur}
                                error={!!formik.touched.cep && !!formik.errors.cep}
                            />
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <Input 
                                label="Logradouro"
                                name="address"
                                value={formik.values.address_street}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Input 
                                label="Número"
                                name="address_number"
                                onChange={formik.handleChange}
                                value={formik.values.address_number}
                                helperText={formik.touched.address_number && formik.errors.address_number}
                                onBlur={formik.handleBlur}
                                error={!!formik.touched.address_number && !!formik.errors.address_number}
                            />
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <Input 
                                label="Complemento"
                                name="complement"
                                onChange={formik.handleChange}
                                value={formik.values.complement}
                                helperText={formik.touched.complement && formik.errors.complement}
                                onBlur={formik.handleBlur}
                                error={!!formik.touched.complement && !!formik.errors.complement}
                            />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                            <Input 
                                label="Bairro"
                                name="neighborhood"
                                onChange={formik.handleChange}
                                value={formik.values.neighborhood}
                                helperText={formik.touched.neighborhood && formik.errors.neighborhood}
                                onBlur={formik.handleBlur}
                                error={!!formik.touched.neighborhood && !!formik.errors.neighborhood}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Input 
                                label="Cidade"
                                name="city"
                                onChange={formik.handleChange}
                                value={formik.values.city}
                                helperText={formik.touched.city && formik.errors.city}
                                onBlur={formik.handleBlur}
                                error={!!formik.touched.city && !!formik.errors.city}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Input 
                                label="Estado"
                                name="state"
                                onChange={formik.handleChange}
                                value={formik.values.state}
                                helperText={formik.touched.state && formik.errors.state}
                                onBlur={formik.handleBlur}
                                error={!!formik.touched.state && !!formik.errors.state}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Tooltip arrow title="A senha deve conter no mínimo 8 carateres, uma letra minúscula, uma letra maiúscula e um número.">
                                <Input 
                                    label="Senha"
                                    type="password"
                                    name="password"
                                    onChange={formik.handleChange}
                                    helperText={formik.touched.password && formik.errors.password}
                                    onBlur={formik.handleBlur}
                                    error={!!formik.touched.password && !!formik.errors.password}
                                />
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Input 
                                label="Confirmar Senha"
                                type="password"
                                name="password_confirmation"
                                onChange={formik.handleChange}
                                helperText={formik.touched.password_confirmation && formik.errors.password_confirmation}
                                onBlur={formik.handleBlur}
                                error={!!formik.touched.password_confirmation && !!formik.errors.password_confirmation}
                            />
                        </Grid>
                        <Grid item marginTop={2}>
                            <Button disabled={!formik.isValid} type="submit">Salvar</Button>
                        </Grid>
                    </Grid>
                    </form>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Paper elevation={1} >
                        <Grid container padding={2} spacing={2}>
                            <Grid item xs={12} marginBottom={2}>
                                <Typography textAlign={"center"} variant="h5">Ofertas</Typography>
                            </Grid>
                            <Grid item xs={12}>
                            <Table 
                                columns={[
                                    { field: 'id', headerName: 'ID', width: 50 },
                                    { field: 'amount', headerName: 'Quantidade', flex: 1, valueFormatter: ({value}) => {
                                        return Number(value).toLocaleString('pt-BR')
                                    }},
                                    { field: 'price_per_k', headerName: 'R$/K', flex: 1, valueFormatter: ({value}) => {
                                        return currency(value).format({
                                            symbol: 'R$ ',
                                            decimal: ',',
                                            separator: '.'
                                        })
                                    }},
                                    {
                                        field: 'status', headerName: 'Status', flex: 1, 
                                        valueGetter: ({value}) => {
                                            return getBidStatusLabel(value)
                                        },
                                        renderCell: ({ formattedValue, row }) => {
                                            return (
                                                <Chip color={getBidStatusColor(row.status)} label={formattedValue} />
                                            )
                                        }
                                    },
                                    {
                                        field: 'actions', headerName: '', sortable: false, filterable: false, disableColumnMenu: true, renderCell: ({row}) => {
                                            return (
                                                <Button variant="outlined" onClick={() => {
                                                    navigate(`/ofertas/${row.id}`)
                                                }}>
                                                    <VisibilityIcon />
                                                </Button>
                                            )
                                        }
                                    }
                                ]}
                                rows={user?.data?.bids || []}
                            />
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper elevation={1} style={{ marginTop: 30 }}>
                        <Grid container padding={2} spacing={2}>
                            <Grid item xs={12} marginBottom={2}>
                                <Typography textAlign={"center"} variant="h5">Pagamentos</Typography>
                            </Grid>
                            <Grid item xs={12}>
                            <Table 
                                columns={[
                                    { field: 'id', headerName: 'ID', width: 50 },
                                    { field: 'due_date', headerName: 'Vencimento', flex: 1, valueFormatter: ({value}) => {
                                        return formatDate(value)
                                    }},
                                    { field: 'amount', headerName: 'Valor', flex: 1, valueFormatter: ({value}) => {
                                        return formatMoney(value)
                                    }},
                                    {
                                        field: 'status', headerName: 'Status', flex: 1, 
                                        valueGetter: ({value}) => {
                                            return getPaymentStatusLabel(value)
                                        },
                                        renderCell: ({ formattedValue, row }) => {
                                            return (
                                                <Chip color={getPaymentStatusColor(row.status)} label={formattedValue} />
                                            )
                                        }
                                    },
                                    {
                                        field: 'actions', headerName: '', sortable: false, filterable: false, disableColumnMenu: true, renderCell: ({row}) => {
                                            return (
                                                <Button variant="outlined" 
                                                    href={`/pagamentos/${row.id}`}>
                                                    <VisibilityIcon />
                                                </Button>
                                            )
                                        }
                                    }
                                ]}
                                rows={user?.data?.payments || []}
                            />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                </Fragment>)}
            </Grid>
        </Layout>
    )
}