import { Alert, Autocomplete, Button, ButtonGroup, CreditCardModal, CurrencyInput, DatePicker, Grid, Input, Layout, Loader, Modal, NumericInput, Typography } from "../../../components";
import { Paper } from "../../../components";
import { useFormik } from "formik";
import { CreateOrderValidationSchema } from "@next-pontos/validations";
import { useAPI, useAxios, useToggle } from "../../../hooks";
import {useEffect, useMemo, useCallback, useState, Fragment, useContext} from 'react'
import useSWR from 'swr'
import { Link, Tooltip, useTheme } from "@mui/material";
import { formatCPF, formatMoney } from "../../../utils";
import { useParams } from "react-router-dom";
import API from "../../../API";
import moment from "moment";
import { AIRLINES_OPTIONS, COMPANY_OPTIONS } from "../../../constants";
import currency from "currency.js";
import VisibilityIcon from '@mui/icons-material/Visibility';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';
import AuthContext from "../../../context/auth";
import { enqueueSnackbar } from 'notistack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as Yup from 'yup'
import LockIcon from '@mui/icons-material/Lock';
import CreditCardIcon from '@mui/icons-material/CreditCard';

export function CreateOrder () { 
    const {me} = useContext(AuthContext)
    const [creditCardModalVisibility, creditCardModalVisibilityControls] = useToggle(false)
    const [cards] = useAxios('/card')

    const [bid, fetchBid] = useAxios({
        url: '/bid/search',
    }, {
        manual: true
    })

    const [order, createOrder] = useAxios({
        url: `/bid/${bid?.data?.id}/order`,
        method: 'POST'
    }, {
        manual: true
    })

    const [passwordData, setPasswordData] = useState<null | { password: string, secondary_password: string}>(null)

    const passwordApi = useAPI()
    
    const searchBidFormik = useFormik({
        initialValues: {
            amount: '',
            pax: '',
            company: '',
            exclude: [] as string[]
        },
        validationSchema: Yup.object({
            amount: Yup.string().required('Este campo é obrigatório.'),
            pax: Yup.string().required('Este campo é obrigatório.'),
            company: Yup.string().required('Este campo é obrigatório.')
        }),
        onSubmit: async (values, helpers) => {
            setPasswordData(null)

            const { data } = await fetchBid({
                params: values
            })

            if (data) {
                helpers.setFieldValue('exclude', [
                    ...values.exclude,
                    data?.id
                ])
            }
        }
    })

    const createOrderFormik = useFormik({
        initialValues: {
            flight_date: '',
            airline: '',
            reservation_code: '',
            fees: '',
            additional_cost: '',
            card_id: '',
            sell_price: '',
            pax_name: '',
            pax: searchBidFormik.values.pax,
            company: searchBidFormik.values.company,
            amount: searchBidFormik.values.amount,
            author_name: me?.name || '',
            origin_airport_code: ''
        },
        validationSchema: CreateOrderValidationSchema,
        onSubmit: async (values) => {
            const { status } = await createOrder({
                data: values
            })

            if (status >= 200) {
                enqueueSnackbar('Emissão criada com sucesso!', {variant: 'success'})
                
                createOrderFormik.resetForm()
                searchBidFormik.resetForm({
                    submitCount: 0
                })
            }
        },
        validateOnMount: true,
    })

    const {amount, pax, company} = searchBidFormik.values

    useEffect(() => {
        createOrderFormik.setValues({
            ...createOrderFormik.values,
            company,
            pax,
            amount
        })
    }, [amount, pax, company])

    useEffect(() => {
        if (me?.name) {
            createOrderFormik.setFieldValue('author_name', me.name)
        }
    }, [me?.name])
    
    const CARDS_OPTIONS = useMemo(() => {
        return (cards.data || []).map((card: { id: number, last_four_digits: string, brand: string, alias: string }) => ({
            value: card.id,
            label: `**** ${card.last_four_digits} (${card.alias})`
        }))
    }, [cards])

    const airlineOptions = useMemo(() => {
        return AIRLINES_OPTIONS.map(item => ({
            value: item.label,
            label: item.label
        }))
    }, [])
    
    const handleRevealPassword = useCallback(async () => {
        const passwordResponse = await passwordApi.get(`/account/${bid?.data?.account?.id}/password`)
        setPasswordData(passwordResponse.data)
    }, [passwordApi, bid?.data?.account.id])

    const paxOptions = useMemo(() => {
        const maxPax = 9
        
        return (new Array(maxPax).fill(0)).map((_, index) => {
            return {
                label: (index + 1).toString(),
                value: (index + 1).toString()
            }
        })
    }, [])

    const companyOptions = useMemo(() => {
        return COMPANY_OPTIONS.map(item => ({
            value: item.value,
            label: item.label
        }))
    }, [])

    const sensitiveInformationStyle = {
        filter: 'blur(10px)'
    }

    if (cards.loading || order.loading) {
        return (
            <Loader />
        )
    }

    return (
        <Layout>
            <Grid container justifyContent="center" spacing={2} alignItems="flex-start" padding={5}>
                <Grid item xs={12} paddingBottom={2}>
                    <Alert color="warning" icon={false}>
                        <strong>Atenção! <br/>Siga todas as etapas abaixo para registrar emissão corretamente!</strong><br/><br/>
                        Preencha os dados básicos para localizar a melhor conta para esta emissão. Em seguida, clique em <strong>"Buscar"</strong>.<br/>
                        Com os dados da conta, preencha os dados da emissão e clique em <strong>"Cadastrar Emissão"</strong>.
                        <br />
                        <br />
                        <strong>
                        A falta de qualquer uma das etapas causará uma falha no cadastramento e falta de pagamento ao fornecedor.
                        </strong>
                        <br />
                        <br />
                        <span style={{
                            fontStyle: 'italic'
                        }}>
                            Caso você tenha algum problema com a conta selecionada (Por exemplo: conta bloqueada, senha incorreta, saldo insuficiente, etc), clique novamente em <strong>"Buscar"</strong> e outra conta será exibida.
                        </span>
                    </Alert>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Paper elevation={1}>
                        <form onSubmit={searchBidFormik.handleSubmit}>
                            <Grid container padding={2} spacing={2} justifyContent="center">
                                <Grid item xs={12} marginBottom={2}>
                                    <Typography textAlign={"center"} variant="h5">Buscar Conta</Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Input 
                                        label="Programa"
                                        name="company"
                                        select
                                        options={companyOptions}
                                        onChange={searchBidFormik.handleChange}
                                        value={searchBidFormik.values.company}
                                        helperText={searchBidFormik.touched.company && searchBidFormik.errors.company}
                                        error={!!searchBidFormik.touched.company && !!searchBidFormik.errors.company}
                                        onBlur={searchBidFormik.handleBlur}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Input 
                                        label="Passageiros"
                                        name="pax"
                                        select
                                        options={paxOptions}
                                        onChange={searchBidFormik.handleChange}
                                        value={searchBidFormik.values.pax}
                                        helperText={searchBidFormik.touched.pax && searchBidFormik.errors.pax}
                                        error={!!searchBidFormik.touched.pax && !!searchBidFormik.errors.pax}
                                        onBlur={searchBidFormik.handleBlur}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <NumericInput
                                        label="Milhas"
                                        name="amount"
                                        onChange={searchBidFormik.handleChange}
                                        onBlur={searchBidFormik.handleBlur}
                                        value={searchBidFormik.values.amount}
                                        helperText={searchBidFormik.touched.amount && searchBidFormik.errors.amount}
                                        error={!!searchBidFormik.touched.amount && !!searchBidFormik.errors.amount}
                                    />
                                </Grid>
                                <Grid item xs={12} textAlign="center">
                                    <Button type="submit">
                                        Buscar
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Paper elevation={1} style={{ userSelect: !bid.data || !searchBidFormik.submitCount ? 'none': undefined }}>
                        <Grid container padding={2} spacing={2}>
                            <Grid item xs={12} marginBottom={2}>
                                <Typography textAlign={"center"} variant="h5">Dados da Conta</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <div
                                    style={{
                                        position: 'relative',
                                        display: 'flex'
                                    }}
                                >
                                {(!bid.data || !searchBidFormik.submitCount) && (<div style={{
                                    display: 'flex',
                                    position: 'absolute',
                                    zIndex: 1,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%',
                                    width: '100%',
                                    flexDirection: 'column'
                                }}>
                                    <LockIcon />
                                    <Typography fontWeight="bold" textAlign="center">
                                        Insira a quantidade de milhas, passageiros e o progama de fidelidade para acessar os dados da conta.
                                    </Typography>
                                </div> )}
                                    <Grid container style={(!bid.data || !searchBidFormik.submitCount) ? sensitiveInformationStyle : undefined}>
                                    {
                                bid.loading ? (
                                    <Grid item xs={12} textAlign="center">
                                        <Loader />
                                    </Grid>
                                ) : (
                                    <Fragment>
                                        <Grid item xs={12}>
                                            <Grid container direction="row">
                                                <Grid item xs={6}>
                                                    <Typography fontWeight="bold">
                                                        ID da Oferta:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography textAlign="end">
                                                        {bid?.data?.id || 'placeholder'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container direction="row">
                                                <Grid item xs={6}>
                                                    <Typography fontWeight="bold">
                                                        ID do Fornecedor:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography textAlign="end">
                                                        {bid?.data?.user_id || 'placeholder'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container direction="row">
                                                <Grid item xs={6}>
                                                    <Typography fontWeight="bold">
                                                        Login:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography textAlign="end">
                                                        {bid?.data?.account?.login || 'placeholder'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container direction="row">
                                                <Grid item xs={6}>
                                                    <Typography fontWeight="bold">
                                                        Senha:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                        <Typography textAlign="end">
                                                    {
                                                        passwordData ? (
                                                            passwordData.password
                                                        ) : (
                                                        <div style={{cursor: 'pointer'}} onClick={handleRevealPassword}>
                                                        <VisibilityIcon fontSize="inherit" />
                                                        </div>
                                                        )
                                                    }
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container direction="row">
                                                <Grid item xs={6}>
                                                    <Typography fontWeight="bold">
                                                        Contrassenha:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                        <Typography textAlign="end">
                                                    {
                                                        passwordData ? (
                                                            passwordData.secondary_password ?? '-'
                                                        ) : (
                                                        <div style={{cursor: 'pointer'}} onClick={handleRevealPassword}>
                                                            <VisibilityIcon fontSize="inherit" />
                                                        </div>
                                                        )
                                                    }
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container direction="row">
                                                <Grid item xs={6}>
                                                    <Typography fontWeight="bold">
                                                        Nome do Titular:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography textAlign="end">
                                                        {bid?.data?.account?.holder_name || 'placeholder'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container direction="row">
                                                <Grid item xs={6}>
                                                    <Typography fontWeight="bold">
                                                        E-mail do Titular:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography textAlign="end">
                                                        {bid?.data?.account?.holder_email || 'placeholder'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container direction="row">
                                                <Grid item xs={6}>
                                                    <Typography fontWeight="bold">
                                                        CPF do Titular:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography textAlign="end">
                                                        {formatCPF(bid?.data?.account?.holder_cpf || '') || 'placeholder'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid marginTop={2} item textAlign="center" xs={12}>
                                            <Typography fontStyle="italic" fontSize={12}>
                                                Caso você tenha algum problema com a conta selecionada (Por exemplo: conta bloqueada, senha incorreta, saldo insuficiente, etc), clique novamente em <strong>"Buscar"</strong> e outra conta será exibida.
                                            </Typography>
                                        </Grid>
                                    </Fragment>
                                )
                            }
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Paper elevation={1} >
                    <form onSubmit={createOrderFormik.handleSubmit}>
                    <Grid container padding={2} spacing={2} justifyContent="center">
                        <Grid item xs={12} marginBottom={2}>
                            <Typography textAlign={"center"} variant="h5">Dados da Emissão</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <div
                                style={{
                                    position: 'relative',
                                    display: 'flex'
                                }}
                            >
                            {(!bid.data || !searchBidFormik.submitCount) && (<div style={{
                                display: 'flex',
                                position: 'absolute',
                                zIndex: 1,
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                                width: '100%',
                                flexDirection: 'column'
                            }}>
                                <LockIcon />
                                <Typography fontWeight="bold" textAlign="center">
                                    Insira a quantidade de milhas, passageiros e o progama de fidelidade para finalizar a emissão.
                                </Typography>
                            </div> )}
                            <Grid container spacing={2} style={(!bid.data || !searchBidFormik.submitCount) ? sensitiveInformationStyle : undefined}>
                            <Grid item xs={12} sm={4}>
                            <DatePicker 
                                label="Data do Voo"
                                value={createOrderFormik.values.flight_date ? moment(createOrderFormik.values.flight_date) : null}
                                onChange={(value) => {
                                    createOrderFormik.setFieldValue('flight_date', value?.toDate(), true)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}> 
                            <Autocomplete 
                                label="Companhia"
                                onChange={({value}) => {
                                    createOrderFormik.setFieldValue('airline', value, true)
                                }}
                                options={airlineOptions}
                                onBlur={createOrderFormik.handleBlur}
                                value={airlineOptions.find(item => item.value === createOrderFormik.values.airline)}
                                helperText={createOrderFormik.touched.airline && createOrderFormik.errors.airline}
                                error={!!createOrderFormik.touched.airline && !!createOrderFormik.errors.airline}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <CurrencyInput
                                label="Taxas (R$)"
                                name="fees"
                                onChangeValue={(_, originalValue) => {
                                    createOrderFormik.setFieldValue('fees', originalValue, true)
                                }}
                                onBlur={createOrderFormik.handleBlur}
                                value={createOrderFormik.values.fees}
                                helperText={createOrderFormik.touched.fees && createOrderFormik.errors.fees}
                                error={!!createOrderFormik.touched.fees && !!createOrderFormik.errors.fees}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Tooltip title="Cartão utilizado para pagamento das taxas." placement="top">
                                <div>
                            <Autocomplete 
                                label="Cartão"
                                onChange={({value}) => {
                                    createOrderFormik.setFieldValue('card_id', value, true)
                                }}
                                options={CARDS_OPTIONS}
                                onBlur={createOrderFormik.handleBlur}
                                value={CARDS_OPTIONS.find((item: { value: string }) => item.value === createOrderFormik.values.card_id) || null}
                                helperText={createOrderFormik.touched.card_id && createOrderFormik.errors.card_id}
                                error={!!createOrderFormik.touched.card_id && !!createOrderFormik.errors.card_id}
                            />
                            </div>
                            </Tooltip>
                        </Grid>

                        <Grid item xs={4}>
                            <Tooltip title="Caso haja mais de um passageiro, insira o nome de apenas um deles." placement="top">
                                <div>
                            <Input
                                label="Nome do Passageiro"
                                name="pax_name"
                                onChange={createOrderFormik.handleChange}
                                onBlur={createOrderFormik.handleBlur}
                                value={createOrderFormik.values.pax_name}
                                helperText={createOrderFormik.touched.pax_name && createOrderFormik.errors.pax_name}
                                error={!!createOrderFormik.touched.pax_name && !!createOrderFormik.errors.pax_name}
                            />
                            </div>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Input
                                label="Aeroporto de Origem"
                                name="origin_airport_code"
                                onChange={e => {
                                    createOrderFormik.setFieldValue('origin_airport_code', e.target.value.replace(/([^A-Z])/ig, '').toLocaleUpperCase().slice(0, 3), true)
                                }}
                                onBlur={createOrderFormik.handleBlur}
                                value={createOrderFormik.values.origin_airport_code}
                                helperText={createOrderFormik.touched.origin_airport_code && createOrderFormik.errors.origin_airport_code}
                                error={!!createOrderFormik.touched.origin_airport_code && !!createOrderFormik.errors.origin_airport_code}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <CurrencyInput
                                label="Valor de Venda (R$)"
                                name="sell_price"
                                onChangeValue={(_, originalValue) => {
                                    createOrderFormik.setFieldValue('sell_price', originalValue, true)
                                }}
                                onBlur={createOrderFormik.handleBlur}
                                value={createOrderFormik.values.sell_price}
                                helperText={createOrderFormik.touched.sell_price && createOrderFormik.errors.sell_price}
                                error={!!createOrderFormik.touched.sell_price && !!createOrderFormik.errors.sell_price}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Input
                                label="Localizador"
                                name="reservation_code"
                                onChange={createOrderFormik.handleChange}
                                onBlur={createOrderFormik.handleBlur}
                                value={createOrderFormik.values.reservation_code}
                                helperText={createOrderFormik.touched.reservation_code && createOrderFormik.errors.reservation_code}
                                error={!!createOrderFormik.touched.reservation_code && !!createOrderFormik.errors.reservation_code}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Tooltip title="Bagagem despachada, marcação de assentos, etc..." >
                                <div>
                                <CurrencyInput
                                    label="Custos Adicionais (R$)"
                                    name="additional_cost"
                                    onChangeValue={(_, originalValue) => {
                                        createOrderFormik.setFieldValue('additional_cost', originalValue, true)
                                    }}
                                    onBlur={createOrderFormik.handleBlur}
                                    value={createOrderFormik.values.additional_cost}
                                    helperText={createOrderFormik.touched.additional_cost && createOrderFormik.errors.additional_cost}
                                    error={!!createOrderFormik.touched.additional_cost && !!createOrderFormik.errors.additional_cost}
                                />
                                </div>
                            </Tooltip>
                        </Grid>
                        {createOrderFormik.values.card_id && (<Grid item textAlign="left" xs={12}>
                            <Button variant="text" size="small" onClick={creditCardModalVisibilityControls.setTrue}>
                                <CreditCardIcon style={{marginRight: 5}} />
                                Ver dados do cartão
                            </Button>
                        </Grid>)}
                        <Grid item textAlign="center" xs={12}>
                            <Button type="submit">Cadastrar Emissão</Button>
                        </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                    </form>
                    </Paper>
                </Grid>
            </Grid>
            {createOrderFormik.values.card_id && (<CreditCardModal
                open={creditCardModalVisibility}
                onClose={creditCardModalVisibilityControls.setFalse} 
                cardId={Number(createOrderFormik.values.card_id)}
            /> )}
        </Layout>
    )
}