import { Alert, Button, CurrencyInput, Grid, Input, Layout, Loader, NumericInput, Table, Typography } from "../../../components";
import { Paper } from "../../../components";
import { useFormik } from "formik";
import { COMPANY_OPTIONS } from "../../../constants";
import { CreateBidValidationSchema } from "@next-pontos/validations";
import { useAxios } from "../../../hooks";
import { useNavigate } from "react-router-dom";
import {useMemo, useEffect, Fragment} from 'react'
import { formatMoney } from "../../../utils";
import * as Yup from 'yup'
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { useTheme } from "@mui/material";
import { PriceSetting } from "../../programs/PriceSettingsInput";

export function NewBid () { 
    const [bid, createBid] = useAxios({
        url: '/bid',
        method: 'POST'
    }, {
        manual: true
    })

    const [dashboard] = useAxios(`/user/dashboard`)
    const [programs] = useAxios(`/program`)
    
    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: {
            company: '',
            miles: '',
            price: '',
            pax: '',
        },
        onSubmit: async values => {
            await createBid({ data: values })
            navigate('/minhas-ofertas')
        },
        validateOnMount: true,
        // @ts-ignore
        validationSchema: CreateBidValidationSchema.concat(Yup.object({
            miles: Yup.number().when('company', ([company], schema) => {
                const min_amount = (programs.data || []).find((program: { company: string }) => program.company === company)?.min_amount || 0
                
                return schema.min(min_amount, `O mínimo para esta companhia é ${Number(min_amount).toLocaleString('pt-BR')} milhas`).required('Este campo é obrigatório').test({
                    test: value => {
                        if (Number(value) <= 0) {
                            return false
                        }
            
                        return true
                    },
                    message: 'Deve ser maior que zero'
                })
            })
        }))
    })

    const paxOptions = useMemo(() => {
        return COMPANY_OPTIONS.find(item => item.value === formik.values.company)?.pax_options || []
    }, [formik.values.company])

    useEffect(() => {
        if (paxOptions.length === 1) {
            formik.setFieldValue('pax', paxOptions[0].value)
        } else {
            formik.setFieldValue('pax', '')
        }
    }, [formik.values.company, paxOptions])

    const lastPricePerK = useMemo(() => {
        const recent = dashboard?.data?.last_seven_days_k_price?.[dashboard?.data?.last_seven_days_k_price?.length - 1]

        return recent?.[formik.values.company]
    }, [dashboard?.data?.last_seven_days_k_price, formik.values.company])

    const theme = useTheme()
    
    const programPriceSettings = useMemo(() => {
        return programs?.data?.find((item: {company: string, program_price_settings: PriceSetting[]}) => item.company === formik.values.company)?.program_price_settings.filter((item: {direction: 'BUY'}) => item.direction === 'BUY') || []
    }, [formik.values.company, programs.data])

    return (
        <Layout>
            <Grid container justifyContent="center" minHeight={"calc(100vh - 64px)"} alignItems="center" padding={3} spacing={2}>
                {bid.loading || dashboard.loading || programs.loading ? (
                    <div style={{
                        display: 'flex',
                        flex: '1',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Loader />
                    </div>
                ) : (
                    <Fragment>
                        <Grid item xs={12} sm={4}>
                            <Paper elevation={1}>
                                <form onSubmit={formik.handleSubmit}>
                                <Grid container padding={2} spacing={2} justifyContent="center">
                                    <Grid item xs={12} marginBottom={2}>
                                        <Typography textAlign={"center"} variant="h5">Nova Oferta</Typography>
                                    </Grid>
                                    {formik.values.company === 'GOL' && (<Grid item xs={12}>
                                        <Alert icon={false} color="success">
                                            Em ofertas <strong>Smiles</strong> utilizamos preferencialmente nossos cartões <strong>Smiles Visa Infinite</strong>, que geram milesback após a data do voo.
                                        </Alert>
                                    </Grid>)}
                                    {programPriceSettings.length > 0 && (<Grid item xs={12}>
                                        <Alert icon={false} color="info">
                                            <strong>Valores Médios de Milheiro (cada mil milhas):</strong>
                                            {
                                                programPriceSettings.map((item: PriceSetting) => {
                                                    return (
                                                        <Fragment>
                                                            <br />
                                                            {
                                                                item.to 
                                                                    ? `De ${Number(item.from).toLocaleString('pt-BR')} Até ${Number(item.to).toLocaleString('pt-BR')}: ${formatMoney(item.price)}`
                                                                    : `A partir de ${Number(item.from).toLocaleString('pt-BR')}: ${formatMoney(item.price)}`
                                                            }
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </Alert>
                                    </Grid>)}
                                    <Grid item xs={12}> 
                                        <Input 
                                            label="Companhia"
                                            name="company"
                                            select
                                            options={COMPANY_OPTIONS.filter(({value}) => {
                                                return (programs.data || []).find((program: {company: string, active: boolean}) => {
                                                    return program.company === value && program.active
                                                })
                                            })}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.company}
                                            helperText={formik.touched.company && formik.errors.company}
                                            error={!!formik.touched.company && !!formik.errors.company}
                                        />
                                    </Grid>
                                    <Grid item xs={12}> 
                                        <NumericInput 
                                            label="Quantidade de Milhas"
                                            name="miles"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.miles}
                                            helperText={formik.touched.miles && formik.errors.miles}
                                            error={!!formik.touched.miles && !!formik.errors.miles}
                                        />
                                    </Grid>
                                    <Grid item xs={12}> 
                                        <CurrencyInput 
                                            label="Valor do Milheiro (R$)"
                                            name="price"
                                            onChangeValue={(_, originalValue) => {
                                                formik.setFieldValue('price', originalValue)
                                            }}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.price}
                                            helperText={(formik.touched.price && formik.errors.price) || (!programPriceSettings && lastPricePerK > 0 ? `Valor sugerido ${formatMoney(lastPricePerK)}`: '')}
                                            error={!!formik.touched.price && !!formik.errors.price}
                                        />
                                    </Grid>
                                    <Grid item xs={12}> 
                                        <Input 
                                            label="Quantidade de CPFs"
                                            name="pax"
                                            select
                                            options={paxOptions}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.pax}
                                            helperText={(formik.touched.pax && formik.errors.pax) || (paxOptions.length <= 1 && 'Esta companhia não possui limitação de CPFs')}
                                            error={!!formik.touched.pax && !!formik.errors.pax}
                                            disabled={paxOptions.length <= 1}
                                        />
                                    </Grid>
                                    <Grid item marginTop={2}>
                                        <Button disabled={!formik.isValid} type="submit">Criar Oferta</Button>
                                    </Grid>
                                </Grid>
                                </form>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={4} height={400}>
                            <Paper elevation={1} sx={{
                                height: '100%',
                                padding: 2
                            }}>
                                <Typography component="h2" variant="h6" color="primary" gutterBottom>
                                    Valores Médios de Mercado
                                </Typography>
                                <div style={{
                                    height: '100%'
                                }}>
                                <ResponsiveContainer
                                    height={'90%'}
                                >
                                    {!dashboard.loading ? (<LineChart
                                        data={(dashboard.data?.last_seven_days_k_price || [])}
                                        margin={{
                                            top: 16,
                                            right: 16,
                                            left: 16,
                                        }}
                                    >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis
                                        dataKey="day"
                                        includeHidden
                                        stroke={theme.palette.text.secondary}
                                    >
                                        
                                    </XAxis>
                                    <YAxis
                                        stroke={theme.palette.text.secondary}
                                        style={theme.typography.body2}
                                        tickFormatter={tick => {
                                            return formatMoney(tick)
                                        }}
                                        tick
                                    >
                                    </YAxis>
                                    {
                                        Object.keys((dashboard.data?.last_seven_days_k_price || [])[0] || {})?.map(item => {
                                            if (item !== 'day' && (formik.values.company ? formik.values.company === item : true)) {
                                                return (
                                                    <Line
                                                        isAnimationActive={false}
                                                        type="monotone"
                                                        dataKey={item}
                                                        stroke={COMPANY_OPTIONS.find(company => company.value === item)?.color}
                                                    />
                                                )
                                            }

                                            return null
                                        })
                                    }
                                    
                                    <Tooltip
                                        formatter={(value: number, name) => {
                                            return [formatMoney(value), COMPANY_OPTIONS.find(item => item.value === name)?.label]
                                        }}
                                        itemStyle={{
                                            color: theme.palette.primary.main
                                        }}
                                        labelStyle={{
                                            fontWeight: 'bold'
                                        }}
                                        wrapperStyle={{
                                            zIndex: 10
                                        }}
                                    />
                                    <Legend
                                        formatter={value => {
                                            return COMPANY_OPTIONS.find(item => item.value === value)?.label
                                        }}
                                        fontWeight="bold"
                                    /> 
                                    </LineChart>) : <div/>}
                                </ResponsiveContainer>
                                </div>
                            </Paper>
                        </Grid>
                </Fragment>)}
            </Grid>
        </Layout>
    )
}