"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.APP_WELCOME_VIDEO = exports.APP_CONTACTS = exports.APP_THEME = exports.APP_DOMAIN = exports.APP_NAME = void 0;
exports.APP_NAME = `Bússola Viagens`;
exports.APP_DOMAIN = 'https://milhas.bussolaviagens.com.br';
exports.APP_THEME = {
    palette: {
        background: {
            default: '#b6b6b6'
        },
        primary: {
            main: '#000096'
        }
    }
};
exports.APP_CONTACTS = {
    telegram: 'bussolaviagens',
    whatsapp: '5562992285143',
    feedback_form_link: ''
};
exports.APP_WELCOME_VIDEO = 'https://www.youtube.com/watch?v=7YBZ_SyoKXc&ab_channel=B%C3%BAssolaViagens';
